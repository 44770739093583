import { Box, Button, Flex, useToast } from '@chakra-ui/react'
import { PcCaseIcon } from 'lucide-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import DataTableThree from '../../../components/DataTableThree'
import { useReconStore } from '../../../hooks'
import AdminAppShell from '../../../layouts/AdminAppShell'
import dayjs from 'dayjs'

export const ViewExceptions = () => {
    const toast = useToast()
    const navigate = useNavigate()
    const downloadExceptionReport = useReconStore((state) => state.downloadExceptionReport)
    const columns = [
        {Header: "File Name", accessor: "file_name", Cell: ({ value }: { value: string }) => {
          // Remove "exp_csvs/" from the file name
          return value.replace(/^exp_csvs\//, "");
      },},
        {Header: "Size", accessor: "size"},
        {Header: "Records", accessor: "records"},
        {Header: "Record Type", accessor: "record_type"},
        {Header: "Created Time", accessor: "created_ts", Cell: ({ value }: { value: string }) => {
          return dayjs(value).format("YYYY-MM-DD HH:mm");
        },},
    ]

    const handleMoreClick = (item: any) => {
       navigate(`/admin/exceptions/${item.id}`)
      }

    const handleDownloadClick = (item: any) => {
        downloadExceptionReport({
            mstId: item.id,
            id: item.id,
            fileType: "csv"
        }).then((res: any) => {
            console.log(res)
            // place notification
        }).catch((error: any) => {
            console.log(error)
            // place notification
        })
    }

    const handleDownloadFunc = async (params: any) => {
      const { format, details } = params;
  
      console.log("see details", details)
  
      try {
        await downloadExceptionReport({ format, search:'', startDate:'', endDate:'', details });
  
        // Show a success toast
        toast({
          title: "Export Successful",
          description: `Your ${format.toUpperCase()} file has been downloaded.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error: any) {
        // Handle specific error responses
        if (error.response) {
          const { status, data } = error.response;
          if (status === 400) {
            toast({
              title: "Export Failed",
              description: data.detail || "Invalid export parameters.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else if (status === 404) {
            toast({
              title: "No Records Found",
              description: "There are no records matching your criteria.",
              status: "warning",
              duration: 5000,
              isClosable: true,
            });
          } else if (status === 401) {
            toast({
              title: "Unauthorized",
              description: "Your session has expired. Please log in again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            // Optionally, redirect to login
          } else {
            toast({
              title: "Export Failed",
              description: "An unexpected error occurred while exporting the data.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } else {
          // Network or other errors
          toast({
            title: "Export Failed",
            description: "A network error occurred. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

  return (
    <AdminAppShell>
        <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="3xl" fontWeight="bold">
          Exceptions Report
        </Box>
      </Flex>

      <Box py={6}>
        <DataTableThree
             columns={columns}
             fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_exceptions`}
             tokenKey="jwt_access_token"
             downloadFunc={handleDownloadFunc}
             showActions={true}
             showExports={false}
             handleMore={handleMoreClick}
             handleDownloadFunc={handleDownloadClick}
        />
      </Box>
    </AdminAppShell>
  )
}
