import React from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Box, Flex, useToast } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../components/DataTableThree";
import { useReconStore } from "../../hooks";
import dayjs from "dayjs";

export const TransactionMao = () => {
  const toast = useToast()
  const downloadTansactionStatusReport = useReconStore(
    (state) => state.downloadTansactionStatusReport
  );

  const columns = [
    {
      Header: "Transaction Date Time",
      accessor: "transaction_datetime",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { Header: "Pan ID", accessor: "pan_id" },
    { Header: "Status", accessor: "status" },
    { Header: "Status Meaning", accessor: "status_meaning" },
    { Header: "Financial ID", accessor: "financial_id" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "Nominal Amount", accessor: "nominal_amount" },
    { Header: "Invoice Financial ID", accessor: "inv_fin_id" },
    // { Header: "Invoice Ref Number", accessor: "invoice_ref_number" },
    {
      Header: "Financial Transaction Concept",
      accessor: "fin_transaction_concept",
    },
    {
      Header: "Invoice Financial Concept",
      accessor: "invoicefin_transaction_concept",
    },
    { Header: "Invoice Reference", accessor: "invreference" },
    { Header: "GUID", accessor: "guid" },
    { Header: "TXN GUID", accessor: "txn_guid" },
  ];

   /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
   const handleDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadTansactionStatusReport({ format, search, startDate, endDate });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description: "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Transaction Statuses
          </Box>
        </Flex>
      </Flex>

      <Box py={4}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/transaction/report`}
          tokenKey="jwt_access_token"
          downloadFunc={() => {}}
          handleDownloadFunc={handleDownloadFunc}
          showActions={false}
        />
      </Box>
    </AdminAppShell>
  );
};
