import {
  Box,
  Flex,
  Spacer,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Stack,
  useToast,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { PcCaseIcon, DownloadIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useReconStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import DataTableFour from "../../components/DataTableFour";
import DataTableThree from "../../components/DataTableThree";
import dayjs from "dayjs";

const TransactionHistory = () => {
  const toast = useToast();
  const downloadTransactionsHistory = useReconStore(
    (state) => state.downloadLatestTransactionPanRecords
  );
  const downloadAllTransactionPanRecords = useReconStore(
    (state) => state.downloadAllTransactionPanRecords
  );

  const downloadTransactionsByPanIdFile = useReconStore(
    (state) => state.downloadTransactionsByPanIdFile
  );

  const allFields: any = [
    {
      label: "Transaction DateTime",
      accessor: "transaction_datetime",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { label: "PAN ID", accessor: "pan_id" },
    { label: "Location ID", accessor: "location_id" },
    { label: "Total Amount", accessor: "total_amount" },
    { label: "Operator", accessor: "pto" },
    { label: "Vehicle ID", accessor: "vehicle_id" },
    { label: "Sync Status", accessor: "external_sync_status" },
    {
      label: "Sync TimeStamp",
      accessor: "external_sync_ts",
      Cell: ({ value }: { value: string }) => {
        // Check if the date is valid using dayjs
        const isValidDate = dayjs(value).isValid();
        return isValidDate
          ? dayjs(value).format("YYYY-MM-DD HH:mm") // Render formatted date
          : "In Queue"; // Render "In Queue" if the date is invalid
      },
    },
    { label: "Remarks", accessor: "remarks" },
    // Add more fields as needed
    // { label: "Field Label", accessor: "field_name" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedFields, setSelectedFields] = useState<string[]>([
    "transaction_datetime",
    "pan_id",
    "location_id",
    "total_amount",
    "pto",
    "vehicle_id",
    "external_sync_status",
    "external_sync_ts",
    "remarks",
  ]);

  const [startDate, setStartDate] = useState<string>(""); // Moved here
  const [endDate, setEndDate] = useState<string>("");

  // New state variables for export functionality
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportType, setExportType] = useState<"full" | "filtered" | null>(
    null
  );
  const [exportFormat, setExportFormat] = useState<"csv" | "excel">("csv");
  const [modalStartDate, setModalStartDate] = useState<string>("");
  const [modalEndDate, setModalEndDate] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState(false);

  const handleExportMenuClick = (type: "full" | "filtered") => {
    setExportType(type);

    if (type === "filtered") {
      // Optionally, pre-fill date ranges if they are managed in a shared state or store
      // For simplicity, we'll leave them empty and let the user input in the modal
      setModalStartDate(startDate);
      setModalEndDate(endDate);
    } else {
      // Reset modal dates for full export
      setModalStartDate("");
      setModalEndDate("");
    }

    setExportFormat("csv"); // Default format
    setIsExportModalOpen(true);
  };

  const handleViewMore = (row: any) => {
    setSelectedRow(row);
    onOpen();
  };

  const relatedTransactionColumns = [
    {
      Header: "Transaction DateTime",
      accessor: "transaction_datetime",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { Header: "PAN ID", accessor: "pan_id" },
    { Header: "Location ID", accessor: "location_id" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "Operator", accessor: "pto" },
    { Header: "Vehicle ID", accessor: "vehicle_id" },
    { Header: "Sync Status", accessor: "external_sync_status" },
    {
      Header: "Sync TimeStamp",
      accessor: "external_sync_ts",
      Cell: ({ value }: { value: string }) => {
        // Check if the date is valid using dayjs
        const isValidDate = dayjs(value).isValid();
        return isValidDate
          ? dayjs(value).format("YYYY-MM-DD HH:mm") // Render formatted date
          : "In Queue"; // Render "In Queue" if the date is invalid
      },
    },
    { Header: "Remarks", accessor: "remarks" },
    // Add more columns as needed
  ];

  /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      console.log("Downloading data...", params);
      await downloadTransactionsHistory({
        format,
        search,
        startDate,
        endDate,
        fields: selectedFields.join(","),
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      console.error("Export failed", error);
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleExportDownload = async () => {
    if (!exportType) {
      toast({
        title: "Export Type Missing",
        description: "Please select an export type.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Validate date range if exportType is 'filtered'
    if (exportType === "filtered") {
      if (
        modalStartDate &&
        modalEndDate &&
        new Date(modalStartDate) > new Date(modalEndDate)
      ) {
        toast({
          title: "Invalid Date Range",
          description: "Start date cannot be after end date.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }

    setIsDownloading(true);

    // Prepare download parameters
    const downloadDetails = {
      format: exportFormat,
      fields: selectedFields.join(","),
      search: undefined, // You can include search if needed
      startDate:
        exportType === "filtered" && modalStartDate
          ? dayjs(modalStartDate).format("YYYY-MM-DD HH:mm:ss")
          : undefined,
      endDate:
        exportType === "filtered" && modalEndDate
          ? dayjs(modalEndDate).format("YYYY-MM-DD HH:mm:ss")
          : undefined,
    };

    try {
      if (exportType === "full") {
        await downloadAllTransactionPanRecords(downloadDetails);
      } else if (exportType === "filtered") {
        await downloadTransactionsHistory(downloadDetails);
      }
      // Close the modal after successful download
      setIsExportModalOpen(false);
    } catch (error: any) {
      // Errors are already handled in the download functions
      setIsExportModalOpen(false);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDownloadTransactionStatementFunc = async (params: any) => {
    const { format, details } = params;

    console.log("see details", details);

    try {
      await downloadTransactionsByPanIdFile({
        format,
        search: "",
        startDate: "",
        endDate: "",
        details,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <AdminAppShell>
      <Flex direction={"column"} gap={"8"}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} gap={"3"}>
            <PcCaseIcon size={30} />
            <Box fontSize="2xl" fontWeight={"bold"}>
              {" "}
              Viewing Transit Transactions By PAN
            </Box>
          </Box>

          <Spacer />
        </Flex>
        <Box>
          <DataTableFour
            allFields={allFields}
            fetchUrl={`${process.env.REACT_APP_BASE_URL}/transactions/latest-transaction-pan-records`}
            tokenKey="jwt_access_token"
            downloadFunc={handleDownloadTransactionStatementFunc}
            handleDownloadFunc={handleDownloadFunc}
            showActions={true}
            handleMore={handleViewMore}
            showDownloadButton={true}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            onExport={handleExportMenuClick}
            startDate={startDate} // Pass startDate
            endDate={endDate} // Pass endDate
            setStartDate={setStartDate} // Pass setStartDate
            setEndDate={setEndDate} // Pass setEndDate
          />
        </Box>
      </Flex>

      {/* Modal for Viewing More Details */}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transaction Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedRow ? (
              <>
                <Box mt={4}>
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Related Transactions for PAN ID: {selectedRow.pan_id}
                  </Text>
                  <DataTableThree
                    columns={relatedTransactionColumns}
                    fetchUrl={`${process.env.REACT_APP_BASE_URL}/transactions/by-pan-id/${selectedRow.pan_id}`}
                    tokenKey="jwt_access_token"
                    downloadFunc={() => {}}
                    showActions={false} // Adjust based on requirements
                    handleMore={() => {}} // No additional actions in related transactions
                    handleDownloadFunc={() => {}} // Implement if needed
                    showEditButton={false}
                    showResubmitButton={false}
                    showDownloadButton={false} // Adjust if download is needed
                    id={selectedRow.pan_id} // Pass PAN ID if necessary
                    showIcons={false}
                    showDateRangeFilter={true}
                    showExports={false}
                  />
                </Box>
              </>
            ) : (
              <Text>No data available.</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* New Export Modal */}
      <Modal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        size="2xl"
        isCentered
        scrollBehavior="outside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Transactions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {/* Summary of Selected Filters */}
              <Box>
                <Text fontWeight="bold" mb={2}>
                  Export Summary:
                </Text>
                {/* <Text>
                  Selected Fields: {selectedFields.join(", ") || "All"}
                </Text> */}
                {exportType === "filtered" && (
                  <>
                    <Text>Start Date: {modalStartDate || "Not specified"}</Text>
                    <Text>End Date: {modalEndDate || "Not specified"}</Text>
                  </>
                )}
              </Box>

              {/* Export Type Selection */}
              <FormControl>
                <FormLabel fontWeight={'bold'}>Select Columns to Include</FormLabel>
                <CheckboxGroup
                  value={selectedFields}
                  onChange={(vals) => setSelectedFields(vals as string[])}
                >
                  <Stack spacing={2}>
                    {/* Use SimpleGrid to arrange fields in multiple columns */}
                    <SimpleGrid columns={[2, 3, 4]} spacing={2}>
                      {allFields.map((field: any) => (
                        <Checkbox key={field.accessor} value={field.accessor}>
                          {field.label}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              {/* Date Range Selection for 'filtered' Export */}
              {exportType === "filtered" && (
                <Box>
                  <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <Input
                      type="date"
                      value={modalStartDate}
                      onChange={(e) => setModalStartDate(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>End Date</FormLabel>
                    <Input
                      type="date"
                      value={modalEndDate}
                      onChange={(e) => setModalEndDate(e.target.value)}
                    />
                  </FormControl>
                </Box>
              )}

              {/* Format Selection */}
              <FormControl as="fieldset">
                <FormLabel as="legend">Select Download Format</FormLabel>
                <RadioGroup
                  onChange={(value) =>
                    setExportFormat(value as "csv" | "excel")
                  }
                  value={exportFormat}
                >
                  <Stack direction="row">
                    <Radio value="csv">CSV</Radio>
                    <Radio value="excel">Excel</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsExportModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleExportDownload}
              isLoading={isDownloading}
              loadingText="Downloading"
            >
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdminAppShell>
  );
};

export default TransactionHistory;
