import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useDisclosure,
  useSteps,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import Datatable from "../../components/DataTable";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";

const initialValues = {
  name: "",
  code: "",
  enabled: false,
  start_loc: "",
  end_loc: "",
  pto_id: "",
};

const initialStopValues = {
  location_seq: 0,
  route_direction: "",
  enabled: true,
  route_id: "",
  location_id: "",
};

const routeDirections = [
  {
    id: 1,
    title: "FORWARD",
    value: "FORWARD",
  },
  {
    id: 2,
    title: "REVERSE",
    value: "REVERSE",
  },
];

const validate = (values: any) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = "Name is required";
  } else if (values.name.length > 25) {
    errors.name = "Name must not exceed 25 characters";
  }

  if (!values.code) {
    errors.code = "Code is required";
  } else if (!/^\d{5}$/.test(values.code)) {
    errors.code = "Code must be exactly 5 digits";
  }

  // if (!values.enabled) {
  //   errors.enabled = "Enabled is required";
  // }

  if (!values.start_loc) {
    errors.start_loc = "Start Location is required";
  }

  if (!values.end_loc) {
    errors.end_loc = "End Location is required";
  }

  if (!values.pto_id) {
    errors.pto_id = "Operator is required";
  }

  return errors;
};

const stopValidate = (values: any) => {
  const errors: any = {};

  if (!values.pto_id) {
    errors.pto_id = "Operator is required";
  }

  if (!values.location_seq) {
    errors.name = "Location Sequence is required";
  }

  if (!values.route_direction) {
    errors.route_direction = "Route Direction is required";
  }

  if (!values.enabled) {
    errors.enabled = "Enabled is required";
  }

  if (!values.route_id) {
    errors.route_id = "Route ID is required";
  }

  if (!values.location_id) {
    errors.pto_id = "Location ID is required";
  }
};

const Routes = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const fetchRoutes = useStore((state) => state.fetchRoutes);
  const createRoute = useStore((state) => state.createRoute);
  const removeRoute = useStore((state) => state.removeRoute);
  const updateRoute = useStore((state) => state.updateRoute);
  const posRoutes = useStore((state) => state.routes);
  const isLoading = useStore((state) => state.loading);
  const createdRoute = useStore((state) => state.createdRoute);
  const [isAddAnotherStop, setIsAddAnotherStop] = useState(false);

  const fetchStops = useStore((state) => state.fetchStops);
  const createStop = useStore((state) => state.createStop);

  const fetchOperators = useStore((state) => state.fetchOperators);
  const posOperators = useStore((state) => state.operators);
  const fetchLocations = useStore((state) => state.fetchLocations);
  const posLocations = useStore((state) => state.locations);
  const [filteredLocations, setFilteredLocations] = useState([]);

  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState(null);
  const [newlyCreatedRoute, setNewlyCreatedRoute] = useState<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      setRoute(null);
      setNewlyCreatedRoute(null);
      setActiveStep(1);
    },
  });
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const {
    isOpen: isAddStopConfirmOpen,
    onOpen: onAddStopConfirmOpen,
    onClose: onAddStopConfirmClose,
  } = useDisclosure();
  const addStopCancelRef = React.useRef(null);

  const steps = [
    { title: "Create a new Route", description: "Creation of a new route" },
    {
      title: "Add the different stops in the route",
      description: "Addition of new stops to an already created route.",
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  useEffect(() => {
    fetchRoutes();
    fetchOperators();
    fetchLocations();
  }, [fetchRoutes, fetchOperators, fetchLocations]);

  const handleSubmit = (routeDetails: any) => {
    setLoading(true);
    createRoute(routeDetails).then((res: any) => {
      console.log(res);
      if (res.name) {
        setLoading(false);

        // filter out the location from the dropdown
        const newLocations = posLocations.filter(
          (location: any) =>
            location.id !== res.start_location.id &&
            location.id !== res.end_location.id
        );

        setFilteredLocations(newLocations);

        // onClose();
        onConfirmOpen();
        // setActiveStep(activeStep + 1);
        setNewlyCreatedRoute(res);
        fetchRoutes();
        toast({
          title: "Success",
          description: "Route was added successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // move to the next step of adding routes
      } else {
        setLoading(false);
      }
    });
  };

  const handleSubmitStop = (stopDetails: any) => {
    setLoading(true);
    stopDetails.route_id = newlyCreatedRoute.id;
    stopDetails.enabled = true;
    createStop(stopDetails).then((res: any) => {
      if (res.route_direction) {
        setLoading(false);
        fetchStops();
        toast({
          title: "Success",
          description: "Stop was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        // Open the confirmation dialog
        onAddStopConfirmOpen();
      } else {
        setLoading(false);
      }
    });
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);
    console.log("handle update submit", updatedData);

    updateRoute(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        if (res.detail) {
          setLoading(false);
          toast({
            title: "Error",
            description: res.detail,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setLoading(false);
          onClose();
          fetchRoutes();
          toast({
            title: "Success",
            description: "Route was updated successfully!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    });
  };

  const handleUpdate = (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      name: item.name,
      code: item.code,
      enabled: item.enabled,
      start_loc: item.start_loc,
      end_loc: item.end_loc,
      pto_id: item.pto_id,
    };

    setRoute(newPayloadFormat);

    if (route) {
      onOpen();
      console.log("route details", route);
      console.log("item details", activeStep);
    }
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/routes/${item.id}`);
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Routes
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setRoute(null);
            setNewlyCreatedRoute(null);
            setOverlay(<OverlayTwo />);
            setActiveStep(1);
            onOpen();
          }}
        >
          Create Route
        </Button>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={() => {
            setNewlyCreatedRoute(null);
            onClose();
            setActiveStep(1);
          }}
          size={route ? `lg` : `full`}
          scrollBehavior={"outside"}
          closeOnOverlayClick={false}
        >
          {overlay}
          <ModalContent>
            {/* <ModalHeader>
              {route ? "Update Existing Route" : "Create a New Route"}
            </ModalHeader> */}
            <ModalCloseButton
              onClick={() => {
                setRoute(null);
                setNewlyCreatedRoute(null);
                setActiveStep(1);
              }}
            />
            <ModalBody
              overflowY="auto" // Enable vertical scrolling
              maxHeight="90vh" // Set maximum height
            >
              <Flex
                gap={"6"}
                direction={route ? "column" : "row"}
                justifyContent={route ? "" : "center"}
                alignItems={route ? "" : "center"}
              >
                {route ? (
                  <></>
                ) : (
                  <Stepper
                    size="sm"
                    orientation="vertical"
                    colorScheme="green"
                    index={activeStep}
                    w={"30%"}
                    height={"70vh"}
                  >
                    {steps.map((step, index) => (
                      <Step key={index}>
                        <StepIndicator>
                          <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                          />
                        </StepIndicator>

                        <Box flexShrink="0">
                          <StepTitle>{step.title}</StepTitle>
                          <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                      </Step>
                    ))}
                  </Stepper>
                )}

                <AlertDialog
                  isOpen={isConfirmOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={onConfirmClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Add Stop
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Would you like to add a Stop on the Route?
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          ref={cancelRef}
                          onClick={() => {
                            // Close both the confirmation dialog and the main modal
                            onConfirmClose();
                            onClose();
                          }}
                        >
                          No
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            // Proceed to the next step to add a stop
                            onConfirmClose();
                            setActiveStep(activeStep + 1);
                          }}
                          ml={3}
                        >
                          Yes
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>

                <AlertDialog
                  isOpen={isAddStopConfirmOpen}
                  leastDestructiveRef={addStopCancelRef}
                  onClose={onAddStopConfirmClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Add Another Stop
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Would you like to add another Stop on the Route?
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          ref={addStopCancelRef}
                          onClick={() => {
                            // Close the confirmation dialog and the main modal
                            onAddStopConfirmClose();
                            onClose();
                            setActiveStep(1);
                            setIsAddAnotherStop(false);
                          }}
                        >
                          No
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            // Proceed to reset the form and change the title
                            onAddStopConfirmClose();
                            setIsAddAnotherStop(true);
                          }}
                          ml={3}
                        >
                          Yes
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>

                <Box boxShadow="inner" w={route ? "100%" : "60%"}>
                  {activeStep === 1 ? (
                    <Box marginY={"4"} boxShadow="#343a40">
                      <Text marginY={"8"} fontSize="2xl" fontWeight={"bold"}>
                        {route
                          ? "Update an Existing route"
                          : "Create a New Route"}
                      </Text>

                      <Formik
                        initialValues={route ? route : initialValues}
                        enableReinitialize
                        validate={validate}
                        onSubmit={route ? submitUpdate : handleSubmit}
                      >
                        <Form>
                          <Stack direction="column" spacing={6}>
                            <Field name="pto_id">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.pto_id && form.touched.pto_id
                                  }
                                >
                                  <FormLabel>
                                    Operator{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Select
                                    {...field}
                                    placeholder="Select an operator"
                                    type="pto_id"
                                    id="pto_id"
                                  >
                                    {posOperators &&
                                      posOperators.map(
                                        (item: any, index: number) => (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                  </Select>
                                  <FormErrorMessage>
                                    {form.errors.pto_id &&
                                      form.touched.pto_id &&
                                      form.errors.pto_id}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="name">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.name && form.touched.name
                                  }
                                >
                                  <FormLabel>
                                    Name <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Input {...field} type="name" id="name" />
                                  <FormErrorMessage>
                                    {form.errors.name &&
                                      form.touched.name &&
                                      form.errors.name}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="code">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.code && form.touched.code
                                  }
                                >
                                  <FormLabel>
                                    Route Code{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Input {...field} type="code" id="code" />
                                  <FormErrorMessage>
                                    {form.errors.code &&
                                      form.touched.code &&
                                      form.errors.code}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="start_loc">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.start_loc &&
                                    form.touched.start_loc
                                  }
                                >
                                  <FormLabel>
                                    Start Location{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Select
                                    {...field}
                                    placeholder="Select a Start Location"
                                    type="start_loc"
                                    id="start_loc"
                                  >
                                    {posLocations &&
                                      posLocations.map(
                                        (item: any, index: number) => (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                  </Select>
                                  <FormErrorMessage>
                                    {form.errors.start_loc &&
                                      form.touched.start_loc &&
                                      form.errors.start_loc}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="end_loc">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.end_loc && form.touched.end_loc
                                  }
                                >
                                  <FormLabel>
                                    End Location{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Select
                                    {...field}
                                    placeholder="Select an End Location"
                                    type="end_loc"
                                    id="end_loc"
                                  >
                                    {posLocations &&
                                      posLocations.map(
                                        (item: any, index: number) => (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                  </Select>
                                  <FormErrorMessage>
                                    {form.errors.end_loc &&
                                      form.touched.end_loc &&
                                      form.errors.end_loc}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="enabled">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.enabled && form.touched.enabled
                                  }
                                >
                                  <FormLabel>
                                    Enabled{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Checkbox
                                    isChecked={field.value}
                                    {...field}
                                    colorScheme="teal"
                                    size="lg"
                                    iconColor="red.500"
                                    type="enabled"
                                    id="enabled"
                                  >
                                    Enabled
                                  </Checkbox>
                                  <FormErrorMessage>
                                    {form.errors.enabled &&
                                      form.touched.enabled &&
                                      form.errors.enabled}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Stack direction="row" spacing={4}>
                              <Button
                                width="100%"
                                colorScheme="teal"
                                variant="solid"
                                type="submit"
                              >
                                {loading ? (
                                  <Spinner size="md" />
                                ) : (
                                  <Box>Submit</Box>
                                )}
                              </Button>
                            </Stack>
                          </Stack>
                        </Form>
                      </Formik>
                    </Box>
                  ) : (
                    <Box>
                      <Text marginY={"8"} fontSize="2xl" fontWeight={"bold"}>
                        {isAddAnotherStop
                          ? "Add Another Stop"
                          : "Create a Stop"}
                      </Text>
                      <Formik
                        initialValues={{
                          ...initialStopValues,
                          pto_id: newlyCreatedRoute
                            ? newlyCreatedRoute.pto_id
                            : "",
                          route_id: newlyCreatedRoute
                            ? newlyCreatedRoute.id
                            : "",
                          enabled: true,
                        }}
                        enableReinitialize
                        validate={stopValidate}
                        onSubmit={handleSubmitStop}
                      >
                        <Form>
                          <Stack w="100%" direction="column" spacing={8}>
                            <Field name="pto_id">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.pto_id && form.touched.pto_id
                                  }
                                >
                                  <FormLabel>
                                    Operator{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Input
                                    {...field}
                                    value={
                                      posOperators.find(
                                        (operator: any) =>
                                          operator.id === field.value
                                      )?.name || ""
                                    }
                                    disabled
                                  />
                                  <FormErrorMessage>
                                    {form.errors.pto_id &&
                                      form.touched.pto_id &&
                                      form.errors.pto_id}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="route_id">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.pto_id && form.touched.pto_id
                                  }
                                >
                                  <FormLabel>
                                    Route{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>

                                  {_.isEmpty(newlyCreatedRoute) ? (
                                    <Select
                                      {...field}
                                      placeholder="Select a route"
                                      type="route_id"
                                      id="route_id"
                                    >
                                      {posRoutes &&
                                        posRoutes.map(
                                          (item: any, index: number) => (
                                            <option key={index} value={item.id}>
                                              {item.name}
                                            </option>
                                          )
                                        )}
                                    </Select>
                                  ) : (
                                    <Input
                                      {...field}
                                      type="route_id"
                                      id="route_id"
                                      value={newlyCreatedRoute?.name}
                                      disabled={true}
                                    />
                                  )}

                                  <FormErrorMessage>
                                    {form.errors.route_id &&
                                      form.touched.route_id &&
                                      form.errors.route_id}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="location_id">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.location_id &&
                                    form.touched.location_id
                                  }
                                >
                                  <FormLabel>
                                    Location{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Select
                                    {...field}
                                    placeholder="Select a location"
                                    type="location_id"
                                    id="location_id"
                                  >
                                    {filteredLocations &&
                                      filteredLocations.map(
                                        (item: any, index: number) => (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        )
                                      )}
                                  </Select>
                                  <FormErrorMessage>
                                    {form.errors.location_id &&
                                      form.touched.location_id &&
                                      form.errors.location_id}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="route_direction">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.route_direction &&
                                    form.touched.route_direction
                                  }
                                >
                                  <FormLabel>
                                    Route Direction{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  {/* <Input
                                    {...field}
                                    type="route_direction"
                                    id="route_direction"
                                  /> */}

                                  <Select
                                    {...field}
                                    placeholder="Select a route direction"
                                    type="route_direction"
                                    id="route_direction"
                                  >
                                    {routeDirections &&
                                      routeDirections.map(
                                        (item: any, index: number) => (
                                          <option
                                            key={index}
                                            value={item.value}
                                          >
                                            {item.title}
                                          </option>
                                        )
                                      )}
                                  </Select>
                                  <FormErrorMessage>
                                    {form.errors.route_direction &&
                                      form.touched.route_direction &&
                                      form.errors.route_direction}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="enabled">
                              {({ field, form }: any) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.enabled && form.touched.enabled
                                  }
                                >
                                  <FormLabel>
                                    Enabled{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </FormLabel>
                                  <Checkbox
                                    {...field}
                                    colorScheme="teal"
                                    size="lg"
                                    iconColor="red.500"
                                    type="enabled"
                                    id="enabled"
                                  >
                                    Enabled
                                  </Checkbox>
                                  <FormErrorMessage>
                                    {form.errors.enabled &&
                                      form.touched.enabled &&
                                      form.errors.enabled}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Stack>

                          <Stack direction="row" spacing={4} marginY="1rem">
                            <Button
                              width="100%"
                              colorScheme="teal"
                              variant="solid"
                              type="submit"
                            >
                              {loading ? (
                                <Spinner size="md" />
                              ) : (
                                <Box>Submit</Box>
                              )}
                            </Button>
                          </Stack>
                        </Form>
                      </Formik>
                    </Box>
                  )}
                </Box>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  onClose();
                  setRoute(null);
                  setNewlyCreatedRoute(null);
                  setActiveStep(1);
                  setIsAddAnotherStop(false);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              header: "Route Name",
              accessorKey: "name",
            },
            {
              header: "Route Code",
              accessorKey: "code",
            },
            {
              header: "Start Location",
              accessorKey: "start_location.name",
            },
            {
              header: "End Location",
              accessorKey: "end_location.name",
            },
            {
              header: "Enabled",
              accessorKey: "enabled",
            },
          ]}
          data={posRoutes}
          dataTableHeading={"Routes"}
          handleDelete={removeRoute}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default Routes;
