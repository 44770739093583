import React, { useEffect, useState } from "react";
import { useReconStore, useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Flex,
  Heading,
  Box,
  Input,
  Spacer,
  Button,
  Text,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import DataTableThree from "../../../components/DataTableThree";
import Datatable from "../../../components/DataTable";
import dayjs from "dayjs";

const Operator: React.FC = () => {
  const toast = useToast()
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleOperator = useStore((state) => state.fetchSingleOperator);
  const fetchOperatorVehicleInfo = useStore(
    (state) => state.fetchOperatorVehicleInfo
  );
  const fetchOperatorRoutesInfo = useStore(
    (state) => state.fetchOperatorRoutesInfo
  );
  const fetchOperatorTariffInfo = useStore(
    (state) => state.fetchOperatorTariffInfo
  );
  const fetchOperatorDriversInfo = useStore(
    (state) => state.fetchOperatorDriversInfo
  );

  const vehicleInfo = useStore((state) => state.vehicleInfo);
  const routesInfo = useStore((state) => state.routesInfo);
  const tariffInfo = useStore((state) => state.tariffInfo);
  const driversInfo = useStore((state) => state.driversInfo);
  const downloadPTOTripDetails = useReconStore(
    (state) => state.downloadPTOTripDetails
  );
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const posOperator = useStore((state) => state.operator);
  const fetchOperatorDeviceInfo = useStore(
    (state) => state.fetchOperatorDeviceInfo
  );
  const operatorInfo = useStore((state) => state.operatorInfo);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDriversModalOpen,
    onOpen: onDriversModalOpen,
    onClose: onDriversModalClose,
  } = useDisclosure();

  const columns = [
    { Header: "Operator Name", accessor: "OperatorName" },
    { Header: "Trip ID", accessor: "TripID" },
    { Header: "Number Of Passengers", accessor: "NumberOfPassengers" },
    { Header: "Route Used", accessor: "RouteUsed" },
    { Header: "Driver", accessor: "Driver" },
    { Header: "Tariff", accessor: "Tariff" },
    { Header: "Total Fare Collected", accessor: "TotalFareCollected" },
    {
      Header: "Trip Start Time",
      accessor: "TripStartTime",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      Header: "TripEndTime",
      accessor: "TripEndTime",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  const fetchTabData = async (index: number) => {
    setLoading(true);
    if (id) {
      const operatorDetails = { operator_id: id };
      try {
        if (index === 0) {
          await fetchOperatorDriversInfo(operatorDetails);
        } else if (index === 1) {
          await fetchOperatorRoutesInfo(operatorDetails);
        } else if (index === 2) {
          await fetchOperatorTariffInfo(operatorDetails);
        } else if (index === 3) {
          await fetchOperatorVehicleInfo(operatorDetails);
        }
      } catch (error) {
        console.error("Error fetching tab data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleOperator({ id: id });
      fetchOperatorDeviceInfo({ operator_id: id });
    }
  }, [fetchSingleOperator, id, fetchOperatorDeviceInfo]);

  useEffect(() => {
    if (isDriversModalOpen) {
      console.log("Drivers modal opened");
      fetchTabData(0); // Preload drivers data when the modal opens
    }
  }, [isDriversModalOpen]);

  /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDownloadFunc = async (params: any) => {
  console.log("handleDownloadFunc called", params);
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadPTOTripDetails({ format, search, startDate, endDate, id });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Operator - {posOperator ? posOperator?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box marginY={"8"}>
        <Card>
          <CardBody>
            <Flex justifyContent={"space-between"} gap={"1rem"}>
              <Box w={"50%"}>
                <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Name:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.name} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Code:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.code} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Address:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.address} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Contact Person:{" "}
                    </Heading>

                    <Input
                      readOnly
                      py="2"
                      value={posOperator?.contact_person}
                    />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Contact Number:{" "}
                    </Heading>

                    <Input
                      readOnly
                      py="2"
                      value={posOperator?.contact_number}
                    />
                  </Flex>
                </Flex>
                <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Total Vehicles:{" "}
                    </Heading>

                    <Input
                      readOnly
                      py="2"
                      value={posOperator?.total_vehicles}
                    />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Total Agents:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.total_agents} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Created:{" "}
                    </Heading>

                    <Input readOnly py="2" value={posOperator?.created_ts} />
                  </Flex>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Updated:{" "}
                    </Heading>

                    <Input
                      readOnly
                      py="2"
                      value={
                        posOperator?.updated_ts
                          ? posOperator?.updated_ts
                          : "N/A"
                      }
                    />
                  </Flex>
                </Flex>
              </Box>
              <Box w={"50%"}>
                <Box>
                  {operatorInfo?.operator ? (
                    <>
                      <Text>
                        Operator{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {operatorInfo.operator.operator_name}
                        </span>{" "}
                        has been connected to the devices below:
                      </Text>
                      <Box display={"flex"} flexDirection={"column"}>
                        {operatorInfo?.operator.devices.map(
                          (device: any, index: number) => (
                            <Badge
                              key={index}
                              variant="solid"
                              colorScheme="green"
                              margin={"4px"}
                              width={"auto"}
                            >
                              {device.device_name}
                            </Badge>
                          )
                        )}
                      </Box>
                    </>
                  ) : (
                    <Text>
                      {operatorInfo?.message || "Operator info not available"}
                    </Text>
                  )}
                </Box>
                <Box mt={8}>
                  <Text fontWeight={"extrabold"}>Other Actions: </Text>
                  <Button
                    mt={2}
                    w={"full"}
                    colorScheme="green"
                    onClick={onOpen}
                  >
                    View Trips Made
                  </Button>

                  <Button
                    mt={2}
                    w={"full"}
                    colorScheme="green"
                    onClick={onDriversModalOpen}
                  >
                    View Operator Drivers, Routes, Tariffs & More
                  </Button>

                  <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        Trips Made by {posOperator?.name}
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <DataTableThree
                          columns={columns}
                          fetchUrl={`${process.env.REACT_APP_BASE_URL}/operators/${id}/trip-details`}
                          tokenKey="jwt_access_token"
                          downloadFunc={downloadPTOTripDetails}
                          handleDownloadFunc={handleDownloadFunc}
                          showActions={false}
                          id={id}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button colorScheme="green" mr={3} onClick={onClose}>
                          Close
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>

                  <Modal
                    isOpen={isDriversModalOpen}
                    onClose={onDriversModalClose}
                    size={"6xl"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>
                        Drivers, Routes, Tariffs & More for {posOperator?.name}
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Tabs
                          isFitted
                          variant="enclosed"
                          onChange={(index) => {
                            setActiveTab(index);
                            fetchTabData(index);
                          }}
                        >
                          <TabList>
                            <Tab>Drivers</Tab>
                            <Tab>Routes</Tab>
                            <Tab>Tariffs</Tab>
                            <Tab>Vehicles</Tab>
                          </TabList>

                          <TabPanels>
                            {/* Drivers Tab */}
                            <TabPanel>
                              {loading && activeTab === 0 ? (
                                <Flex
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Spinner size="lg" />
                                </Flex>
                              ) : driversInfo && driversInfo.length > 0 ? (
                                <Datatable
                                  headerColumns={[
                                    {
                                      header: "Driver Name",
                                      accessorKey: "name",
                                    },
                                    {
                                      Header: "Contact Number",
                                      accessorKey: "contact_number",
                                    },
                                    {
                                      Header: "Email",
                                      accessorKey: "contact_email",
                                    },
                                    {
                                      Header: "Enabled",
                                      accessorKey: "enabled",
                                    },
                                  ]}
                                  data={driversInfo}
                                  dataTableHeading={"Operator Drivers"}
                                  handleDelete={() => {}}
                                  handleUpdate={() => {}}
                                  handleViewMore={() => {}}
                                  isSearch={true}
                                  isActions={false}
                                  isLoading={false}
                                />
                              ) : (
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  py={6}
                                >
                                  <Text>No Drivers found</Text>
                                </Box>
                              )}
                            </TabPanel>

                            {/* Routes Tab */}
                            <TabPanel>
                              {loading && activeTab === 1 ? (
                                <Flex
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Spinner size="lg" />
                                </Flex>
                              ) : routesInfo && routesInfo.length > 0 ? (
                                <Datatable
                                  headerColumns={[
                                    {
                                      header: "Route Name",
                                      accessorKey: "name",
                                    },
                                    {
                                      header: "Route Code",
                                      accessorKey: "code",
                                    },
                                    {
                                      Header: "Start Location",
                                      accessorKey: "start_location.name",
                                    },
                                    {
                                      Header: "End Location",
                                      accessorKey: "end_location.name",
                                    },
                                    {
                                      Header: "Enabled",
                                      accessorKey: "enabled",
                                    },
                                  ]}
                                  data={routesInfo}
                                  dataTableHeading={"Operator Routes"}
                                  handleDelete={() => {}}
                                  handleUpdate={() => {}}
                                  handleViewMore={() => {}}
                                  isSearch={true}
                                  isActions={false}
                                  isLoading={false}
                                />
                              ) : (
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  py={6}
                                >
                                  <Text>No Routes found</Text>
                                </Box>
                              )}
                            </TabPanel>

                            {/* Tariffs Tab */}
                            <TabPanel>
                              {loading && activeTab === 2 ? (
                                <Flex
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Spinner size="lg" />
                                </Flex>
                              ) : tariffInfo && tariffInfo.length > 0 ? (
                                <Datatable
                                  headerColumns={[
                                    {
                                      header: "Tariff Code",
                                      accessorKey: "code",
                                    },
                                    {
                                      header: "Route Name",
                                      accessorKey: "route_name",
                                    },
                                    {
                                      header: "Amount",
                                      accessorKey: "amount",
                                    },
                                    {
                                      header: "Validity",
                                      accessorKey: "validity",
                                    },
                                    {
                                      header: "Enabled",
                                      accessorKey: "enabled",
                                    },
                                  ]}
                                  data={tariffInfo}
                                  dataTableHeading={"Operator Tariffs"}
                                  handleDelete={() => {}}
                                  handleUpdate={() => {}}
                                  handleViewMore={() => {}}
                                  isSearch={true}
                                  isActions={false}
                                  isLoading={false}
                                />
                              ) : (
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  py={6}
                                >
                                  <Text>No Tarrifs found</Text>
                                </Box>
                              )}
                            </TabPanel>

                            <TabPanel>
                              {loading && activeTab === 3 ? (
                                <Flex
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <Spinner size="lg" />
                                </Flex>
                              ) : vehicleInfo && vehicleInfo.length > 0 ? (
                                <Datatable
                                  headerColumns={[
                                    {
                                      header: "Vehicle Name",
                                      accessorKey: "name",
                                    },
                                    {
                                      header: "Vehicle Number",
                                      accessorKey: "vehicle_number",
                                    },
                                    {
                                      header: "Model",
                                      accessorKey: "model",
                                    },
                                    {
                                      header: "Type",
                                      accessorKey: "type",
                                    },
                                    {
                                      header: "Enabled",
                                      accessorKey: "enabled",
                                    },
                                  ]}
                                  data={vehicleInfo}
                                  dataTableHeading={"Operator Vehicles"}
                                  handleDelete={() => {}}
                                  handleUpdate={() => {}}
                                  handleViewMore={() => {}}
                                  isSearch={true}
                                  isActions={false}
                                  isLoading={false}
                                />
                              ) : (
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  py={6}
                                >
                                  <Text>No vehicles found</Text>
                                </Box>
                              )}
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Operator;
