import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Input,
  Spacer,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const Driver = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const fetchSingleDriver = useStore((state) => state.fetchSingleDriver);
  const posDriver = useStore((state) => state.driver);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showNewPin, setShowNewPin] = useState(false); // Toggle for new PIN visibility
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const resetDriverPassword = useStore((state) => state.resetDriverPassword);

  useEffect(() => {
    if (id) {
      fetchSingleDriver({ id: id });
    }
  }, [fetchSingleDriver, id]);

  const ChangePinSchema = Yup.object().shape({
    newPin: Yup.string()
      .length(5, "User password must be exactly 5 characters long")
      .required("New PIN is required"),
    confirmPin: Yup.string()
      .oneOf([Yup.ref("newPin")], "PINs must match")
      .required("Please confirm your PIN"),
  });

  const handlePinChange = (values: any, { setSubmitting, resetForm }: any) => {
    console.log("PIN Changed to:", values.newPin);
    // Add your backend logic to change the PIN here

    resetDriverPassword({ id: id, newPin: values.newPin }).then((res: any) => {
      if (res.id) {
        resetForm();
        onClose(); // Close modal after submission
        setSubmitting(false);

        toast({
          title: "PIN Changed.",
          description: "The driver's PIN has been successfully changed.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "An error occurred while changing the driver's PIN.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Driver -{" "}
            {posDriver ? `(${posDriver?.pto_code} - ${posDriver?.name})` : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box>
        <Card>
          <CardBody>
            <Flex direction={"row"} gap={"10"} justifyContent={"center"}>
              {/* Left Column with driver details */}
              <Flex
                direction={"column"}
                gap={"3"}
                justifyContent={"center"}
                w={"50%"}
              >
                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Name:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.name} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    National ID:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.national_id} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Contact Number:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.contact_number} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Contact Email:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.contact_email} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Address:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.address} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Gender:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.gender} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    User Code:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.user_code} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Operator Name:{" "}
                  </Heading>
                  <Input readOnly py="2" value={`${posDriver?.pto_code} - ${posDriver?.name}`} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Created:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.created_ts} />
                </Flex>

                <Flex alignItems={"center"} gap={"2"}>
                  <Heading size="md" w={"250px"}>
                    Updated:{" "}
                  </Heading>
                  <Input readOnly py="2" value={posDriver?.updated_ts ? posDriver?.updated_ts : "N/A" } />
                </Flex>
              </Flex>

              {/* Right Column with the Change Login Pin Button */}
              <Flex direction={"column"} w={"50%"}>
                <Text fontWeight={"bold"}>Other Actions:</Text>
                <Button
                  colorScheme="green"
                  size="lg"
                  my={4}
                  onClick={() => {
                    onOpen();
                  }}
                  w={"full"}
                >
                  Change Login Pin
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>

      {/* Modal for changing the PIN */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Login Pin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{ newPin: "", confirmPin: "" }}
              validationSchema={ChangePinSchema}
              onSubmit={handlePinChange}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <Flex direction={"column"} gap={"3"}>
                    {/* New PIN Field with Toggle */}
                    <InputGroup>
                      <Field
                        as={Input}
                        name="newPin"
                        type={showNewPin ? "text" : "password"}
                        placeholder="Enter new PIN"
                      />
                      <InputRightElement>
                        <IconButton
                          variant="ghost"
                          icon={showNewPin ? <ViewOffIcon /> : <ViewIcon />}
                          onClick={() => setShowNewPin(!showNewPin)}
                          aria-label="Toggle PIN visibility"
                        />
                      </InputRightElement>
                    </InputGroup>
                    {errors.newPin && touched.newPin ? (
                      <Text color="red.500">{errors.newPin}</Text>
                    ) : null}

                    {/* Confirm PIN Field with Toggle */}
                    <InputGroup>
                      <Field
                        as={Input}
                        name="confirmPin"
                        type={showConfirmPin ? "text" : "password"}
                        placeholder="Confirm new PIN"
                      />
                      <InputRightElement>
                        <IconButton
                          variant="ghost"
                          icon={showConfirmPin ? <ViewOffIcon /> : <ViewIcon />}
                          onClick={() => setShowConfirmPin(!showConfirmPin)}
                          aria-label="Toggle confirm PIN visibility"
                        />
                      </InputRightElement>
                    </InputGroup>
                    {errors.confirmPin && touched.confirmPin ? (
                      <Text color="red.500">{errors.confirmPin}</Text>
                    ) : null}
                  </Flex>

                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Change Pin
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </AdminAppShell>
  );
};

export default Driver;
