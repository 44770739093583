import React, { useEffect } from "react";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { useParams, useNavigate } from "react-router-dom";
import { useStore } from "../../../hooks";
import { 
  Box, 
  Button, 
  Card, 
  CardBody, 
  Flex, 
  Heading, 
  Input, 
  Spacer, 
  UnorderedList, 
  ListItem, 
  Divider 
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";

const permissions = [
  {
    category: "User Management",
    items: ["View Users", "Create Users", "Update Users", "Delete Users"],
  },
  {
    category: "Role Management",
    items: [
      "View Roles",
      "Assign Roles",
      "Create Roles",
      "Update Roles",
      "Delete Roles",
    ],
  },
  {
    category: "Operator Management",
    items: [
      "View Operators",
      "Create Operators",
      "Update Operators",
      "Delete Operators",
      "Manage All Operator Component Activities"
    ],
  },
  {
    category: "Transaction Management",
    items: [
      "View Transactions",
      "Update Transactions",
    ],
  },
  {
    category: "Reconciliation Management",
    items: [
      "View Reconciliation Reports",
      "Perform Reconciliation",
    ],
  },
  {
    category: "Trip Management",
    items: [
      "View Trips Details",
    ],
  },
  {
    category: "Validation List Management",
    items: [
      "View Validation List Items",
    ],
  }
];

const filterPermissionsByRole = (role: any) => {
  if (role === "Super Admin User") {
    return permissions; // Show all permissions
  }
  if (role === "Administrator") {
    // Exclude User Management and Role Management
    return permissions.filter(
      (permission) =>
        permission.category !== "User Management" &&
        permission.category !== "Role Management"
    );
  }
  if (role === "Operator") {
    // Show only Operator Management
    return permissions.filter(
      (permission) => permission.category === "Operator Management" || permission.category === "Trip Management"
    );
  }
  if (role === "Finance") {
    // Show Transaction Management and Reconciliation Management
    return permissions.filter(
      (permission) =>
        permission.category === "Transaction Management" ||
        permission.category === "Reconciliation Management" ||
        permission.category === "Trip Management" ||
        permission.category === "Validation List Management"
    );
  }
  return []; // Return empty if no matching role
};

const Role = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleRole = useStore((state) => state.fetchSingleRole);
  const posRole = useStore((state) => state.role);

  useEffect(() => {
    if (id) {
      fetchSingleRole({ id: id });
    }
  }, [fetchSingleRole, id]);

  const filteredPermissions = filterPermissionsByRole(posRole?.title);

  return (
    <AdminAppShell>
      {/* Header Section */}
      <Flex
        mb={8}
        justifyContent="space-between"
        alignItems="center"
        px={6}
        py={4}
        borderRadius="md"
        boxShadow="sm"
      >
        <Flex alignItems="center" gap={4}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="2xl" fontWeight="bold">
            Role: {posRole?.title || "Loading..."}
          </Box>
        </Flex>
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      {/* Main Content Section */}
      <Flex gap={8} px={6}>
        {/* Role Details Section */}
        <Box flex="2">
          <Card border="1px solid" borderColor="gray.200" boxShadow="sm">
            <CardBody>
              <Heading size="lg" mb={6}>
                Role Details
              </Heading>
              <Flex direction="column" gap={4}>
                <Flex alignItems="center" gap={4}>
                  <Heading size="sm" w="200px">
                    Title:
                  </Heading>
                  <Input readOnly variant="filled" value={posRole?.title || ""} />
                </Flex>

                <Flex alignItems="center" gap={4}>
                  <Heading size="sm" w="200px">
                    Description:
                  </Heading>
                  <Input
                    readOnly
                    variant="filled"
                    value={posRole?.description || ""}
                  />
                </Flex>

                <Flex alignItems="center" gap={4}>
                  <Heading size="sm" w="200px">
                    Created:
                  </Heading>
                  <Input
                    readOnly
                    variant="filled"
                    value={posRole?.created_ts || ""}
                  />
                </Flex>

                <Flex alignItems="center" gap={4}>
                  <Heading size="sm" w="200px">
                    Updated:
                  </Heading>
                  <Input
                    readOnly
                    variant="filled"
                    value={posRole?.updated_ts || ""}
                  />
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </Box>

        {/* Permissions List Section */}
        <Box flex="1">
          <Card border="1px solid" borderColor="gray.200" boxShadow="sm">
            <CardBody>
              <Heading size="lg" mb={6}>
                Permissions
              </Heading>
              {filteredPermissions.map((permission) => (
                <Box key={permission.category} mb={4}>
                  <Heading size="md" mb={3} color="green.600">
                    {permission.category}
                  </Heading>
                  <UnorderedList spacing={2} pl={5}>
                    {permission.items.map((item) => (
                      <ListItem key={item} fontSize="sm">
                        {item}
                      </ListItem>
                    ))}
                  </UnorderedList>
                  {permission !== filteredPermissions[filteredPermissions.length - 1] && (
                    <Divider mt={4} />
                  )}
                </Box>
              ))}
            </CardBody>
          </Card>
        </Box>
      </Flex>
    </AdminAppShell>
  );
};

export default Role;
