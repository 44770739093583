import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

const Tariff = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleTariff = useStore((state) => state.fetchSingleTariff);
  const posTariff = useStore((state) => state.tariff);

  const tariffTypes = [
    {
      id: 1,
      name: "Bus",
      value: "B",
    },
    {
      id: 2,
      name: "Minibus-Taxi",
      value: "T",
    },
    {
      id: 3,
      name: "Rail",
      value: "R",
    },
    {
      id: 4,
      name: "Air",
      value: "A",
    },
    {
      id: 5,
      name: "Sea",
      value: "S",
    },
  ];

  useEffect(() => {
    if (id) {
      fetchSingleTariff({ id: id });
    }
  }, [fetchSingleTariff, id]);

  const getTariffType = (type: string) => {
    const actualType = tariffTypes.find((item) => {
      return item.id === parseInt(type);
    });
    return actualType?.name || "Unknown Type";
  }

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Tariff - {posTariff ? posTariff?.code : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box mt={2}>
        <Card align={"center"}>
          <CardBody>
            <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
            <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Route Name:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.route_name} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Route Code:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.route_code} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                 Tariff Code:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.code} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Start Location:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.start_location_name} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  End Location:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.end_location_name} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Amount:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.amount} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Type:{" "}
                </Heading>
                <Input readOnly py="2" value={getTariffType(posTariff?.type)} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Validity:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.validity_date} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Status:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.enabled ? "Enabled" : "Disabled"} />
              </Flex>


              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Created:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.created_ts} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Updated:{" "}
                </Heading>
                <Input readOnly py="2" value={posTariff?.updated_ts ? posTariff?.updated_ts : 'N/A'} />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Tariff;
